import React from "react";
import '../css/home.css';
import home_apps from "../../img/home_apps.png";
import home_pricing1 from "../../img/home_pricing1.png";
import home_pricing2 from "../../img/home_pricing2.png";
import home_pricing3 from "../../img/home_pricing3.png";

export default function Pricing() {
    return (
        <>
            <div className="container-fluid pt-5 pb-5 text-center">
                <div className="container">
                    <p className="subheader1 pb-5">In One All-Inclusive App</p>
                    <img className="img-fluid pb-5" alt="Haven Station on multiple platforms." src={home_apps} /><br/>
                    <p className="subheader2 pt-2">Pricing</p>
                    <div class="row align-items-center">
                        <div class="mx-auto col-12 col-md-4 pt-5">
                            <img className="img-fluid" alt="All Aboard!" src={home_pricing1} /><br/><br/>
                            <span className="title">$2.99/Month</span><br/><br/>
                            <span className="body">Grants access to all of our <br/> original shows!</span>
                        </div>
                        <div class="mx-auto col-12 col-md-4 pt-5">
                            <img className="img-fluid" alt="Taking Off!" src={home_pricing2} /><br/><br/>
                            <span className="title">$4.99/Month</span><br/><br/>
                            <span className="body">Unlocks limited access to <br/> games &amp; activities!</span>
                        </div>
                        <div class="mx-auto col-12 col-md-4 pt-5">
                            <img className="img-fluid" alt="Destination Haven Station!" src={home_pricing3} /><br/><br/>
                            <span className="title">$13.99/Month</span><br/><br/>
                            <span className="body">Full access to all content, <br/> including our VR world!</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}