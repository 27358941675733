import React from "react";
import '../css/ourstory.css';
import britt_son from "../../img/brittany-son.png";


export default function Story() {
    return (
        <>
            <div className="container-fluid pt-5 pb-5">
              <div className="container">
                <div className="row">
                    <div className="col-lg-4 pt-5 text-center d-none d-md-block">
                        <img className="img-fluid img" alt="Brittany Vaughn and her son" src={britt_son} />
                    </div>
                    <div className="col-lg-8 pt-5 d-none d-md-block">
                        <p className="title">A Parent's Need for Her Child's Safety</p>
                        <p className="body pb-5">Digital media has forever changed the way our children experience the world around them. As a parent with a young child, our founder understands this deeply. Vauven's story began when Brittany Vaughn struggled to find apps that engaged and inspired her toddler without also exposing him to violent or otherwise inappropriate content.</p>
                        <p className="body">After consulting with a close friend and fellow mom, our founder asked herself: "What if there was a central digital space that had wholesome, faith-based entertainment that inspired kids and put parents at ease?" The need for children's safety in our digital age is still what drives Vauven today.</p>
                    </div>

                    <div className="mx-auto col-auto pt-5 d-md-none text-center">
                        <span className="title">A Parent's Need for<br/> Her Child's Safety</span><br/>
                        <img className="img-fluid img pt-5" alt="Brittany Vaughn and her son" src={britt_son} />
                    </div>
                    <div className="col-lg-8 pt-5 d-md-none">
                        <p className="body pb-3">Digital media has forever changed the way our children experience the world around them. As a parent with a young child, our founder understands this deeply. Vauven's story began when Brittany Vaughn struggled to find apps that engaged and inspired her toddler without also exposing him to violent or otherwise inappropriate content.</p>
                        <p className="body">After consulting with a close friend and fellow mom, our founder asked herself: "What if there was a central digital space that had wholesome, faith-based entertainment that inspired kids and put parents at ease?" The need for children's safety in our digital age is still what drives Vauven today.</p>
                    </div>
                </div>
              </div>
            </div>
        </>
    );
}