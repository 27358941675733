import React from "react";
import '../css/home.css';

export default function Information() {
    return (
        <>
            <div className="container-fluid pb-5">
                <div className="container text-center">
                    <div class="row align-items-center">
                        <div class="col-lg-4 pt-5">
                            <span className="material-symbols-outlined info_icons">church</span><br/>
                            <span className="title">Rooted in Faith</span><br/>
                            <span className="body">Our characters and content are inspired by biblical parables.</span>
                        </div>
                        <div class="col-lg-4 pt-5">
                            <span class="material-symbols-outlined info_icons">interests</span><br/>
                            <span className="title">All-in-One Fun</span><br/>
                            <span className="body">In addition to shows, we have games, activities, and more!</span>
                        </div>
                        <div class="col-lg-4 pt-5">
                            <span class="material-symbols-outlined info_icons">supervised_user_circle</span><br/>
                            <span className="title">Ages 2-10</span><br/>
                            <span className="body">All of our content is carefully curated for children ages 2 to 10.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}