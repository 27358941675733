import React from 'react';
import './css/footer.css';
import logo from "../img/logo.png";
import { Link } from 'react-router-dom';

export default function Navbar() {
    return (
        <footer className="page-footer pt-5 pb-5">
            <div className="container-fluid">
                <div className="row justify-content-center pb-3">
                <a className="navbar-brand nav-large" href={"/"}><img className="img-fluid img-large" alt="Vauven logo" src={logo} /></a>
                </div>
                
                <div className="row align-items-center">
                    <div className="col-6 d-flex flex-lg-row flex-md-row flex-sm-row flex-column pl-5">
                        <div className="pr-3"><Link className="text-decoration-none ft-lnk" to={"/our-story"}>
                            ABOUT</Link></div>
                        <div className="">
                                    <a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="mailto:info@vauven.com">
                                    CONTACT</a>
                        </div>
                    </div>
                    <div className="col-6 d-flex flex-sm-row-reverse flex-column-reverse text-right d-lg-none d-xl-none d-inline-flex d-sm-inline-flex d-md-inline-flex pr-5">
                        <div className="pl-3"><a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/vauven">LinkedIn</a></div>
                        <div className="pl-3"><a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="https://www.instagram.com/vauvenllc">Instagram</a></div>
                        <div className="pl-3"><a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="https://www.facebook.com/vauvenllc">Facebook</a></div>
                    </div>

                    <div className="col-6 d-none d-lg-flex flex-lg-row-reverse pr-5 d-md-none d-lg-inline-flex">
                        <div className="pl-5"><a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/vauven"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className='filter' viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg></a></div>
                        <div className="pl-5"><a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="https://www.instagram.com/vauvenllc"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className='filter' viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg></a></div>
                        <div className="pl-5"><a className="text-decoration-none ft-lnk" target="_blank" rel="noreferrer" href="https://www.facebook.com/vauvenllc"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className='filter' viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg></a></div>
                    </div>
                </div>
            </div>
        </footer>

    );
}