import React from "react";
import '../css/ourstory.css';


export default function Values() {
    return (
        <>
            <div className="container-fluid pt-5 pb-5">
              <div className="container">
                <p className="subheader1 text-center">Values</p><br/>
                <div className="row pt-3">
                    <div className="col-auto col-lg-1">
                        <span class="material-symbols-outlined info_icons">balance</span>
                    </div>
                    <div className="col-auto col-lg-11">
                        <p className="title">Integrity</p>
                        <p className="body pb-5">Above all else, we aim to be an honest and transparent company. We're here to make children's lives more fun and parent's lives less stressful, and take that charge very seriously. Operating with integrity keeps us grounded in our Christian principles and focused on our long-term mission.</p>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-auto col-lg-1">
                        <span class="material-symbols-outlined info_icons">verified_user</span>
                    </div>
                    <div className="col-auto col-lg-11">
                        <p className="title">Safety</p>
                        <p className="body pb-5">Children's safety in digital spaces is our priority. We know that what children get exposed to online is important, and take every precaution to ensure that all of our content is both age appropriate and wholesome.</p>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-auto col-lg-1">
                        <span class="material-symbols-outlined info_icons">handshake</span>
                    </div>
                    <div className="col-auto col-lg-11">
                        <p className="title">Trust</p>
                        <p className="body pb-5">We know it can be difficult for parents to trust that online spaces will take good care of their kids. That's why we make every effort to create products for kids that families can believe in.</p>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-auto col-lg-1">
                        <span class="material-symbols-outlined info_icons">rocket_launch</span>
                    </div>
                    <div className="col-auto col-lg-11">
                        <p className="title">Innovation</p>
                        <p className="body pb-5">We're not like other family entertainment companies. We'll always explore different research-backed ways for kids to to create fun and inspiring experiences for kids.</p>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-auto col-lg-1">
                        <span class="material-symbols-outlined info_icons">stars</span>
                    </div>
                    <div className="col-auto col-lg-11">
                        <p className="title">Awesomeness</p>
                        <p className="body pb-5">At Vauven, we aim to provide experiences that balance parents' needs for safety with kids' needs for fun. Overall, we want anyone who interacts with our products to walk away thinking "that's awesome!"</p>
                    </div>
                </div>
              </div>
            </div>
        </>
    );
}