import React from "react";
import '../css/ourteam.css';
import brittany from "../../img/brittany.png";
import kim from "../../img/kim.png";
import dakota from "../../img/dakota.png";
import $ from 'jquery';

export default function Team() {
    $('#brittanyModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var recipient = button.data('whatever') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this)
        modal.find('.modal-body input').val(recipient)
    })

    $('#kimModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var recipient = button.data('whatever') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this)
        modal.find('.modal-body input').val(recipient)
    })

    $('#dakotaModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var recipient = button.data('whatever') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this)
        modal.find('.modal-body input').val(recipient)
    })

    return (
        <>
            <div className="container-fluid pt-5">
                <div className="container">
                <p className="header2 text-center">Founding Team</p><br/>
                <p className="body text-center pb-5">Click on one of the pictures to view their bio.</p>
                    <div className="row">
                        <div className="mx-auto col-sm-8 col-md-4 pb-5 text-center">
                            <img className="img-fluid img btn-bio" data-toggle="modal" data-target="#brittanyModal" data-whatever="@brittany" alt="Brittany Vaughn" src={brittany} /><br/><br/>
                            <span className="title">Brittany Vaughn</span><br/>
                            <span className="body">FOUNDER</span>
                        </div>
                        <div className="mx-auto col-sm-8 col-md-4 text-center pb-5">
                            <img className="img-fluid img btn-bio" data-toggle="modal" data-target="#kimModal" data-whatever="@kim" alt="Kim T&eacute;llez" src={kim} /><br/><br/>
                            <span className="title">Kim T&eacute;llez</span><br/>
                            <span className="body">PRODUCT DESIGNER</span>
                        </div>
                        <div className="mx-auto col-sm-8 col-md-4 text-center">
                            <img className="img-fluid img btn-bio" data-toggle="modal" data-target="#dakotaModal" data-whatever="@dakota" alt="Dakota Brown" src={dakota} /><br/><br/>
                            <span className="title">Dakota-Cheyenne Brown</span><br/>
                            <span className="body">FRONT-END DEVELOPER</span>
                        </div>
                    </div>

                    

                    <div class="modal fade" id="brittanyModal" tabindex="-1" aria-labelledby="brittanyModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Bio</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="mx-auto col-auto text-center pb-3">
                                            <img className="img-fluid img" alt="Brittany Vaughn" src={brittany} /><br/><br/>
                                            <span className="title">Brittany Vaughn</span><br/>
                                            <span className="body">FOUNDER</span>
                                        </div>
                                        <div className="mx-auto col-auto col-md-7">
                                            <p className="body">Brittany is the founder of Vauven. She is an expert when it comes to discovering new business initiatives. Brittany has had the dream of giving children and families a grand entertainment experience ever since she was a young girl in 2001, in the car with her family returning home from a popular theme park. After the experience from the trip, Brittany knew her purpose was to create exciting spaces, whether in person or online, for people to enjoy.  Only after having a child of her own did she realize the need for a fun and safe space available and accessible to the next generation and made it her mission to make the dream a reality.</p>
                                            <p className="body">Brittany is the brains behind the conceptualization of Vauven and Haven Station as well as a writer for the pilot episodes of Haven Station's first original content shows. A creative at heart, Brittany has an eye for up-and-coming animation talent and manages the animation team that has made Haven Station come to life.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="kimModal" tabindex="-1" aria-labelledby="kimModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Bio</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="mx-auto col-auto text-center pb-3">
                                            <img className="img-fluid img" alt="Kim T&eacute;llez" src={kim} /><br/><br/>
                                            <span className="title">Kim T&eacute;llez</span><br/>
                                            <span className="body">PRODUCT DESIGNER</span>
                                        </div>
                                        <div className="mx-auto col-auto col-md-7">
                                            <p className="body">Kim joined Vauven during the idea-phase and became responsible for designing the company brand as well as the MVP mobile app, Haven Station. Their role extends to everything from conducting research to validate ideas, to facilitating meetings, to all things UX and UI design. Kim designed the Vauven website, logo, and company style guide. They also designed and built the Haven Station prototype, including the logo and custom UI elements.</p>
                                            <p className="body">Kim's favorite part about working at Vauven is having the space to grow as a product designer and a leader.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="dakotaModal" tabindex="-1" aria-labelledby="dakotaModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Bio</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="mx-auto col-auto text-center pb-3">
                                            <img className="img-fluid img" alt="Dakota Brown" src={dakota} /><br/><br/>
                                            <span className="title">Dakota-Cheyenne<br/>Brown</span><br/>
                                            <span className="body">FRONT-END DEVELOPER</span>
                                        </div>
                                        <div className="mx-auto col-auto col-md-7">
                                            <p className="body">Dakota-Cheyenne joined Vauven during the idea-phase and became responsible for implementing the front-end design, namely through the website. Dakota-Cheyenne worked closely with Kim to ensure that the vision for the design was implemented.</p>
                                            <p className="body">Dakota-Cheyenne's favorite part about working at Vauven is having the opportunity to grow as a front-end developer and be an advocate for children.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </>
    );
}