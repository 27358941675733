import React from "react";
import Header from "./ourstory/Header";
import Story from "./ourstory/Story";
import Mission from "./ourstory/Mission";
import Values from "./ourstory/Values";
import Journey from "./ourstory/Journey";


export default function OurStory() {
    return (
        <>
            <Header />
            <Story />
            <Mission />
            <Values />
            <Journey />
        </>
    );
}