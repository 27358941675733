import React from "react";
import Header from "./home/Header";
import Information from "./home/Information";
import Characters from "./home/Characters";
import Journey from "./home/Journey";
import TryIt from "./home/TryIt";
import Pricing from "./home/Pricing";

export default function Home() {
    return (
        <>
            <Header />
            <Information />
            <Characters />
            <Journey />
            <TryIt />
            <Pricing />
        </>
    );
}