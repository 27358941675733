import React from "react";
import '../css/home.css';
import home_hero from "../../img/home_hero.png";
import { Link } from 'react-router-dom';


export default function Header() {
    return (
        <>
            <div className="container-fluid home pt-5 pb-5">
                <div className="container">
                    <div className="row">
                    <div className="col-auto d-lg-none d-block text-center">
                            <span className="small-header">vauven presents</span>
                            <br/>
                            <span className="header1">Haven Station</span>
                            <br/>
                            <span className="subheader1">An App You Can Trust</span>
                            <br/><br/>
                            <span className="subtext">Our <i>awesome</i> all-in-one app keeps kids entertained, without compromising your peace of mind.</span>
                            <br/><br/>
                            <p><Link className="text-decoration-none ft-lnk" to={"/prototype"}><button className="button">PROTOTYPE</button></Link></p>
                        </div>
                        <div className="mx-auto d-lg-none d-block col-auto justify-content-center align-items-center">
                            <img className="img-fluid" alt="Haven Station Mascot" src={home_hero} />
                        </div>

                        <div className="col-auto d-none d-lg-block col-lg-6">
                            <span className="small-header">vauven presents</span>
                            <br/>
                            <span className="header1">Haven Station</span>
                            <br/>
                            <span className="subheader1">An App You Can Trust</span>
                            <br/><br/>
                            <span className="subtext">Our <i>awesome</i> all-in-one app keeps kids entertained, without compromising your peace of mind.</span>
                            <br/><br/>
                            <p><Link className="text-decoration-none ft-lnk" to={"/prototype"}><button className="button">PROTOTYPE</button></Link></p>
                        </div>
                        <div className="mx-auto col-auto col-lg-6 d-none d-lg-block justify-content-center align-items-center">
                            <img className="img-fluid" alt="Haven Station Mascot" src={home_hero} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}