import React from "react";
import '../css/home.css';

export default function Journey() {
    return (
        <>
            <div className="container-fluid pb-5">
                <div className="container text-center">
                    <p className="subheader2">The Journey</p>
                    <div class="row align-items-center">
                        <div class="col-lg-4 pt-lg-5">
                            <span className="material-symbols-outlined char_icons">live_tv</span><br/>
                            <span className="title">Original Shows</span><br/>
                            <span className="body">You won't find episodes of our shows anywhere else!</span>
                        </div>
                        <div class="col-lg-4 pt-5">
                        <span class="material-symbols-outlined char_icons">sports_esports</span><br/>
                            <span className="title">Fun Games</span><br/>
                            <span className="body">Kids can play games with their favorite characters!</span>
                        </div>
                        <div class="col-lg-4 pt-5">
                            <span class="material-symbols-outlined char_icons">palette</span><br/>
                            <span className="title">Activities, &amp; More!</span><br/>
                            <span className="body">Keep the fun going with printables and activities!</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}