import React from "react";
import Header from "./ourteam/Header";
import Team from "./ourteam/Team";
import JoinUs from "./ourteam/JoinUs";


export default function OurTeam() {
    return (
        <>
            <Header />
            <Team />
            <JoinUs />
        </>
    );
}