import React from "react";
import '../css/ourteam.css';

export default function Header() {

    return (
        <>
            <div className="container-fluid header-team pt-5 pb-5">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-12">
                            <p className="header2 text-center">Our Team</p><br/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}