import React, { useState } from 'react';
import {NavLink} from 'react-router-dom';
import './css/navbar.css';
import logo from "../img/logo.png";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar nav-nav sticky-top navbar-expand-lg navbar-light'>
        <div className="container-fluid">
                  <a className="navbar-brand nav-large" href={"/"}>
                    <img className="img-fluid nav-large" alt="Vauven logo" src={logo} />
                  </a>
        <div className='menu-icon' onClick={handleClick}>
          <div className={click ? '' : 'fa-box'}><i className={click ? 'fas fa-times' : 'fas fa-bars fa-box'} /></div>
        </div>
        <ul className={click ? 'nav-menu active' : 'navbar-nav nav-menu'}>
          <li className="nav-item dropdown pr-3">
            <button type='button' className="nav-link btn-clr dropdown-toggle btn" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                ABOUT
            </button>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <NavLink activeClassName="active" className="dropdown-item" to={"/our-story"} onClick={closeMobileMenu}>Our Story</NavLink>
                <NavLink activeClassName="active" className="dropdown-item" to={"/our-team"} onClick={closeMobileMenu}>Our Team</NavLink>
            </div>
          </li>
          <li className='nav-item pr-3'>
            <a target="_blank" 
              rel="noreferrer"
              href="mailto:info@vauven.com"
              className='nav-link a'
              activeClassName="active"
              onClick={closeMobileMenu}
            >
              CONTACT
            </a>
          </li>
          <li className='nav-item'>
            <NavLink activeClassName="active" to={"/prototype"} onClick={closeMobileMenu}><button className="button">PROTOTYPE</button></NavLink>
          </li>
        </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;