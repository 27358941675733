import React from "react";
import '../css/prototype.css';

export default function Header() {

    return (
        <>
            <div className="container-fluid header-prototype pt-5 pb-5">
              <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-12">
                    <p className="header2 text-center">Prototype</p><br/>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}