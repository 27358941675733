import React from "react";
import '../css/home.css';
import Carousel from "../Carousel";

export default function Characters() {

    return (
        <>
            <div className="container-fluid character pt-5 pb-5">
              <div className="container">
                <p className="header2 text-center">All Aboard!</p><br/>
                <p className="subtext pt-3">The journey to Haven Station begins with a brand-new cast of original characters that take kids on adventures full of fun, learning, and faith.</p>
                <p className="subheader2 text-center pt-3 pb-3">Our Characters</p> 
              </div>
              <Carousel />
            </div>
        </>
    );
}
