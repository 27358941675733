import React from "react";
import '../css/home.css';
import Book from "../../img/book_logo.png";
import Fruit from "../../img/find_fruit.png";
import Activities from "../../img/activities.png";
import TV_icon from "../../img/tv_icon.png";
import Controller_icon from "../../img/controller_icon.png";
import Palette_icon from "../../img/palette_icon.png";
import VR_icon from "../../img/vr_icon.png";
import VR_image from "../../img/vr_image.png";

export default function Information() {
    return (
        <>
            <div className="container-fluid tryit pt-5 pb-5">
                <div className="container">
                    <div className="text-center">
                        <p className="header2">Try it out with Danny*</p><br/>
                        <img className="img-fluid" alt="The Book Logo" src={Book} /><br/><br/>
                    </div>
                    <div className="pt-5 pb-5 modal-lg mx-auto">
                        <img className="image-fluid" alt="TV Icon" src={TV_icon} />&nbsp;&nbsp;<span className="subheader2 align-middle">Original Show: The Book</span><br/>
                        <p className="body pb-2">Danny's mom gives him a special book on his birthday. To his surprise, the book comes alive when he and his mom read it together. Join the adventure as Danny is teleported into The Book!</p>
                        <div className="video_div">
                            <iframe src="https://player.vimeo.com/video/739501381?h=049f65d232&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen className="video" title="Danny &amp;amp; The Pharaoh"></iframe>
                        </div>
                    </div>
                    <div className="pt-5 pb-5 modal-lg mx-auto">
                        <img className="image-fluid" alt="Controller Icon" src={Controller_icon} />&nbsp;&nbsp;<span className="subheader2 align-middle">Game: Guess the Fruit</span>
                        <p className="body pb-2">Danny will describe a fruit to you. You must find the correct fruit Danny is describing and bring it to him before time runs out! <a className="try-a" target="_blank" rel="noreferrer" href="https://vauven.com/game/">Click here</a>, or the photo to try out the game!</p>
                        <a target="_blank" rel="noreferrer"href="https://vauven.com/game/"><img className="img-fluid" alt="Find the Fruit screen" src={Fruit} /></a>
                    </div>
                    <div className="pt-5 pb-5 modal-lg mx-auto">
                        <img className="image-fluid" alt="Palette Icon" src={Palette_icon} />&nbsp;&nbsp;<span className="subheader2 align-middle">Activities: Coloring Pages</span>               
                        <p className="body pb-2">Can be printed or downloaded and colored digitally!</p>
                        <img className="img-fluid" alt="Activity page examples" src={Activities} />
                    </div>
                    <div className="pt-5 modal-lg mx-auto">
                        <p className="small-header">Coming Soon!</p>
                        <img className="image-fluid" alt="Virtual Reality Icon" src={VR_icon} />&nbsp;&nbsp;<span className="subheader2 align-middle">Virtual Reality World: Play in Danny's Room!</span>
                        <p className="body pb-2">Let's take the fun to another dimension! In our virtual reality world, kids can play with their favorite characters in their homes, just like in the shows!</p>
                    </div>
                    <div className="modal-lg mx-auto pb-5"><img className="img-fluid" alt="Virtual reality examples" src={VR_image} /></div>
                    <p className="body">*Proof of Concept Note: the following images, videos, and related artifacts are designed to demonstrate a proof of concept and may not be reproduced without permission.</p>
                </div>
            </div>
        </>
    );
}