import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import OurStory from './components/Ourstory';
import OurTeam from './components/Ourteam';
import Prototype from './components/Prototype';
import Footer from './components/Footer';
import {Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/Scrolltop';

export default function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/our-story" element={<OurStory />}></Route>
            <Route path="/our-team" element={<OurTeam />}></Route>
            <Route path="/prototype" element={<Prototype />}></Route>
            <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}