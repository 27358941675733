import React from "react";
import '../css/ourteam.css';

export default function JoinUs() {

    return (
        <>
            <div className="container-fluid pt-5 pb-5">
              <div className="container pb-5">
                <p className="header2 text-center">Work with us!</p><br/>
                <p className="body text-center pb-5">As an early-stage start-up, we are looking for folks whose values align with ours. If you want to create awesome and safe digital experiences for kids, send us a line and your resume! We're looking for animators, illustrators, designers, and developers who believe in our mission.</p>
                <div className="text-center"><a target="_blank" rel="noreferrer" href="mailto:info@vauven.com"><button className="btn-team">CONTACT</button></a></div>
              </div>
            </div>
        </>
    );
}