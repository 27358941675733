import React from "react";
import '../css/ourstory.css';


export default function Mission() {
    return (
        <>
            <div className="container-fluid mission pt-5 pb-5">
              <div className="container">
                <p className="subheader1 text-center">Mission Statement</p><br/>
                <p className="body">Vauven is a family entertainment company. Our goal is to provide children with exciting experiences through our products.</p>
                <p className="body">Our mission is to inspire the bright minds of our future by creating safe, wholesome, educational, and enjoyable content for our next generation.</p>
              </div>
            </div>
        </>
    );
}