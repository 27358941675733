import React from "react";
import '../css/ourteam.css';
import journey from "../../img/journey.png";

export default function Journey() {

    return (
        <>
            <div className="container-fluid journey pt-5 pb-5">
                <div className="container">
                    <p className="subheader1 text-center pb-5">Our Journey</p>
                    <div className="row align-items-center">

                    <div className="mx-auto col-sm-8 pb-5 text-center d-md-none">
                        <span class="material-symbols-outlined info_icons">emoji_objects</span><br/><br/>
                        <span className="year">2020</span><br/>
                        <span className="title">Finding Vision</span><br/><br/>
                        <span className="body">Our vision is to create an innovative space for children, starting with an all-inclusive app in a safe digital space.</span>
                    </div>
                    <div className="mx-auto col-sm-8 text-center pb-5 d-md-none">
                        <span class="material-symbols-outlined info_icons">person_add</span><br/><br/>
                        <span className="year">2021</span><br/>
                        <span className="title">Building the Team</span><br/><br/>
                        <span className="body">We built a team of hardworking people who each played an integral part in the construction of Vauven.</span>
                    </div>
                    <div className="mx-auto col-sm-8 text-center d-md-none">
                        <span class="material-symbols-outlined info_icons">security_update_good</span><br/><br/>
                        <span className="year">2022</span><br/>
                        <span className="title">Creating the Dream</span><br/><br/>
                        <span className="body">In order to begin bringing our ideas to life, we created our website and app prototype.</span>
                    </div>

                    <div className="col-md-12 d-none d-md-block">
                        <img style={{paddingLeft: 171, paddingRight: 191}} className="img-fluid img" alt="Vauven timeline" src={journey} />
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}