import React from "react";
import '../css/ourteam.css';

export default function Header() {

    return (
        <>
            <div className="container-fluid header-story pt-5 pb-5">
              <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-12">
                    <p className="header2 text-center">Our Story</p><br/>
                    <p className="subtext text-center">Vauven arose from the need to safely<br/>engage, excite, and educate children in our digital age.</p>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}