import React from "react";

export default function Frame() {

    return (
        <>
            <div className="container-fluid pt-5 pb-5">
              <div className="container">
                <div className="row">
                  <div className="mx-auto justify-content-center align-items-center">
                  <iframe src="https://marvelapp.com/prototype/80g9h73?emb=1&iosapp=false&frameless=false" allowTransparency="true" frameborder="0" className="prototype" title="Haven Station Prototype"></iframe>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}