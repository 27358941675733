import React from "react";
import Header from "./prototype/Header";
import Frame from "./prototype/Frame";


export default function Prototype() {
    return (
        <>
            <Header />
            <Frame />
        </>
    );
}