import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import Fab from "@material-ui/core/Fab";
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Danny from "../img/danny.png";
import Cruze from "../img/cruzecrew.png";
import Shamba from "../img/shamba.png";
import Omar from "../img/omar.png";
import Leabea from "../img/leabea.png";

export const data = [
  {
    cover: Danny,
    title: "Danny",
  },
  {
    cover: Shamba,
    title: "Shamba",
  },
  {
    cover: Leabea,
    title: "LeaBea",
  },
  {
    cover: Omar,
    title: "Omar",
  },
  {
    cover: Cruze,
    title: "Cruze Crew",
  },
];


export default function ResponsiveCarousel(props) {
  const ref = React.useRef();
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 5;
          if (parentWidth <= 1080) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={300}
              carouselWidth={parentWidth}
              data={data}
              currentVisibleSlide={currentVisibleSlide}
              customScales={[1, 0.50, 0.40, 0.55]}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <Fab
          style={{color:"#E6EDEF", background:"#2E515D", position: "absolute", top: "40%", left: 10, zIndex: 10 }}
          size="small"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <NavigateBefore />
        </Fab>
        <Fab
          style={{color:"#E6EDEF", background:"#2E515D", position: "absolute", top: "40%", right: 10, zIndex: 10 }}
          size="small"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <NavigateNext />
        </Fab>
      </>
    </div>
  );
}

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below 
export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        userSelect: "contain",
      }}
      className="my-slide-component"
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
          borderRadius: 0,
        }}
        draggable={false}
        src={cover}
        alt={"Vauven Characters"}
      />
    </div>
  );
});